import { axiosInstance } from "../../../Interceptors/http-interceptor";

export default class UserService {


    static login = (data) => {

        const headers = { 'apiKey': "c087d609d3b24454b8c5b83a712b4016ece5578ef8e645caba6bc3e45d0f0a1d" }

        return axiosInstance.post(`auth`, { Username: data.UserName, Password: data.Password }, { headers });
    }



}